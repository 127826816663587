import {createMuiTheme } from '@material-ui/core/styles';


export default createMuiTheme({
  palette: {
    primary: {
      main: '#282d31',// '#2a2d35',
      light: '#90969e',//'#3b3d46',
      dark: '#212529'//'#31343c'     
    },
    secondary: {
      main: '#4caf50',
      light: '#8bc34a', 
      dark: '#1b5e20'
     },
     success: {
       main: '#4caf50'
     },
     error: {
       main: '#c62828',
       light: '#f05545'
     },
     warning: {
      main: '#ffab00'
    },
     contrastText: '#212529'
  },
  shadows: ["none"],

  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  },
  
  overrides: {
    MuiAppBar: {
      root : {
        padding: 0,
      }, 
    },
    MuiTableHead : {
      root: {
        backgroundColor: '#212529', 
        color: '#1b5e20'
      }
    },
    MuiTableCell : {
      root: {
        borderBottomColor: '#000',
        color: 'white'
      }
    },
    MuiDivider: {
      root: {
        color: '#C00',
        marginTop: 14,
        marginBottom: 14,
      }
    },
    MuiInputBase: {
      root: {
        color: '#fff'
      }
    },
    MuiSnackbarContent : {
      root : {
        backgroundColor: '#c62828'
      }
    },
    MuiPaper: {
        root : {
          backgroundColor:'#212529',
          color: '#FFF',
          fontSize: 14,
          padding: 14,
          margin: 10
        }
    },
    MuiListItemSecondaryAction: {
      root: {
        fontSize: 14
      }
    },
    MuiList: {
       root : {
         color: 'white',
         backgroundColor:'#343a40', 
         marginTop: 14
       }
    },
    MuiListItemText : {
      root: {
        color: 'white'
      }
    },
    MuiTypography: {
      '& p': {
        color: 'white'
      },
      subheading: {
        color: 'white'
      }   
    },
    MuiDialog : {
      root : {
        background: 'rgba(0,0,0,0.5)'
      }
    },
    MuiDialogTitle : {
      root: {
        backgroundColor: '#0f1013',
        marginBottom: 14,
      }
    }
  },
});