import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

const styles = theme => ({
  card: {
    backgroundColor: '#3b3d47'
  },

  cardheader : {
    backgroundColor: '#3b3d47', 
    color: 'white'
  },
  cardcontent: {
      color: grey[100]
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: '#3b3d47',
    margin: 10,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  table: {
      marginTop: 20
  },
  tableHeader: {
      color: '#fff'
  },
  tableHeaderShort: {
    width: 10, 
    color: '#FFF'
},
  tableHeaderHighlight: {
      backgroundColor:  theme.palette.warning.main,
      color: '#000'
      },
  tableHeaderAuction: {
        backgroundColor:  '#4a5258',
        color: '#000'
        }
});
const TOLERANCE = 600;
class EbayListings extends React.Component {
  state = { expanded: false };

  render() {
    const { classes } = this.props;

          return (
            <Grid container spacing={24}>
            <Grid item xs={12}>
     
            <Table className={classes.table} aria-label="simple table">
                <TableHead >
                <TableRow>
                    <TableCell className={classes.tableHeader}>Ebay Listing</TableCell>
                    <TableCell  className={classes.tableHeader} align="center">Price</TableCell>
                    <TableCell  className={classes.tableHeader}  align="center">BIN</TableCell>
                    <TableCell className={classes.tableHeader}  align="center">Time</TableCell>
                    <TableCell className={classes.tableHeader}  align="center">Watchers / Bids</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>

            {this.props.ebaydata
            .map(row => {
              return (
              <TableRow key={row.itemId}  className={row.listingInfo.listingType === "Auction" ? classes.tableHeaderAuction :  ((row.listingInfo.buyItNowPrice ? row.listingInfo.buyItNowPrice : row.sellingStatus.currentPrice) <= TOLERANCE ? classes.tableHeaderHighlight : classes.tableHeader )}>
                  <TableCell component="th" scope="row">
                    <Link href={row.viewItemURL} target="_blank" rel="noopener" color={(row.listingInfo.buyItNowPrice ? row.listingInfo.buyItNowPrice : row.sellingStatus.currentPrice) <= TOLERANCE ? "primary" : "secondary"} >{row.title}</Link>
                  </TableCell>
                  <TableCell  className={classes.tableHeader } align="center">{row.sellingStatus.currentPrice}</TableCell>
                  <TableCell  className={classes.tableHeader} align="center">{row.listingInfo.buyItNowPrice}</TableCell>
                  <TableCell  className={classes.tableHeader} align="center"><Moment fromNow ago>{row.listingInfo.listingType === "Auction" ? row.listingInfo.endTime : row.listingInfo.startTime}</Moment>{row.listingInfo.listingType === "Auction" ? " left" : " ago"}</TableCell>
                  <TableCell  className={classes.tableHeader} align="center">{row.listingInfo.watchCount ? row.listingInfo.watchCount : 0} / {row.sellingStatus.bidCount}</TableCell>
                </TableRow>
              );
          })}

                 </TableBody>
            </Table>
            </Grid>
              </Grid>
    
          );
      }  


  }


  EbayListings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EbayListings);