import 'whatwg-fetch'
const API_URL = "https://www.rigradar.com/api/index.php?ip=";

const minerApi = {
    getResults: getResults,
    getChiaPoolInfo : getChiaPoolInfo,
    getChiaMinerStats: getChiaMinerStats
}
function getResults(user) {
    var request = new Request(API_URL + user.split(':')[0] + "&port=" + user.split(':')[1], {
        method: 'GET'
    })
    return fetch(request);
}

function getChiaPoolInfo() {
    var request = new Request("https://api.flexpool.io/v2/pool/currentLuck?coin=XCH", {
        method: 'GET'
    })
    return fetch(request);
}

function getChiaMinerStats(address) {
    var request = new Request("https://api.flexpool.io/v2/miner/stats?coin=XCH&address=" + address, {
        method: 'GET'
    })
    return fetch(request);
}

export default minerApi;