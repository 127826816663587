import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '../theme'
import AnimatedNumber from 'react-animated-number';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import TrendingFlat from '@material-ui/icons/TrendingFlat';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import Paper from '@material-ui/core/Paper';


//const prettyMs = require('pretty-ms');
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");
  }

const styles = theme => ({
  card: {
    backgroundColor: '#3b3d47',
  },
  cardcontent: {
      color: 'white'
  },
  cardheader : {
    backgroundColor: '#3b3d47', 
    
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: grey[900],
  },
  metricUnit : {
    color: theme.palette.secondary.main,
    fontSize: 28
  }
});

class RecipeReviewCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;

    return (
     <MuiThemeProvider theme={theme}>
      <Paper>
          <h3>HASHRATE</h3>
          
            <AnimatedNumber component="Text" value={this.props.hashrate}
                style={{
                    transition: '0.8s ease-out',
                    transitionProperty: 'background-color,color,opacity',
                    fontSize: 96
                }}
                frameStyle={perc =>(
                    perc === 100 ? {} : {opacity: 0.25}
                )}
                stepPrecision={2}
                fomatValue={n => numberWithCommas(n)}
                />
            <span className={classes.metricUnit}>{this.props.metric}</span>
            {this.props.average && (
            <div style={{float: 'right',paddingTop:22, color: theme.palette.primary.light}}>
              <ArrowDropUp style={{position: 'relative', top: '8px'}}/>{this.props.max}<br/>
              <TrendingFlat style={{position: 'relative', top: '8px'}}/>{Math.round(this.props.average * 100) / 100}
              <br/><ArrowDropDown style={{position: 'relative', top: '8px'}}/>{this.props.min}</div>
            )}
      </Paper>
      </MuiThemeProvider>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);