import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import grey from '@material-ui/core/colors/grey';

import theme from '../theme'

import Grid from '@material-ui/core/Grid';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

function fanUnit(metric) {
  return metric === "Percent" ? '%' : 'rpm';
}
function getHashShare(share, total) {
  return ((share/total) * 100).toFixed(2);
}
function getHwErrorPercent(error, total) {
  return ((error/total) * 100).toFixed(2);
}
const styles = theme => ({
  card: {
    backgroundColor: '#3b3d47'
  },
  cardheader : {
    backgroundColor: '#3b3d47', 
    color: 'white'
  },
  cardcontent: {
      color: grey[100]
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: '#3b3d47',
    margin: 10,
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
});
const listPadding = 8;
class GpuList extends React.Component {
  state = { expanded: false };

  render() {

          return (
            this.props.data.map(row => {
              return (
                  <Grid item xs={12} sm={6} md={2}>
                    <Paper style={{backgroundColor: theme.palette.primary.main, padding: 0}}>
                      <div className="listHeader">{row.device}</div>
                      <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                      {row.temperature && (
                        <div><div className="listContent">Temperature: <span>{row.temperature}&deg; / {row.memoryTempurature}&deg;</span></div>
                        <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        </div>
                      )}

                      {row.fanSpeed && (
                        <div><div className="listContent">Fan speed:  <span>{row.fanSpeed}{fanUnit(this.props.fan)}</span></div>
                        <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        </div>
                      )}
                      {row.power && (
                        <div><div className="listContent">Power:  <span>{(row.power).toFixed(1)}W</span></div>
                        <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        </div>
                      )}

                      <div className="listContent">Hash rate:  <span>{(row.hashrate ).toFixed(2)}</span></div>
                      <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                      <div className="listContent">Rate share:  <span style={{color: row.hashrate > this.props.hash / this.props.data.length ? theme.palette.secondary.main : ''}}>
                        {getHashShare(row.hashrate, this.props.hash)}%</span></div>
                        <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        {row && (
                        <div><div className="listContent">HW Err:  <span>
                          {row.hwError}/{row.shares} <span style={{color: getHwErrorPercent(row.hwError, row.shares) > 2 ? theme.palette.error.light : '', paddingLeft: 3}}> ({getHwErrorPercent(row.hwError, row.shares)}%)</span></span></div>
                        <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        </div>
                      )}
                        {row.pcieAddress && (
                        <div>
                          <div className="listContent">PCIE:  <span>{row.pcieAddress}</span>
                        </div>
                          <Divider style={{marginTop:listPadding, marginBottom: listPadding }}/>
                        </div>
                      )}
                      
                    </Paper>
                  </Grid> 
              );
          })
          );
      }  


  }


GpuList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GpuList);