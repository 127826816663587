import React, { Component } from "react";
import PropTypes from "prop-types";
import EbayListings from "./components/EbayListings";
import AppBar from "./components/AppBar";
import Graph from "./components/Graph";
import Setup from "./components/Setup";
import GpuList from "./components/GpuList";
import "./App.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import minerApi from "./services/MinerService";
import ebayApi from "./services/EbayService";
import MergeType from "@material-ui/icons/MergeType";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import theme from "../src/theme";
import MainHashRate from "./components/MainHashRate";
import Divider from "@material-ui/core/Divider";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import PowerIcon from "@material-ui/icons/Power";
import NetworkCheck from "@material-ui/icons/NetworkCheck";
import Pool from "@material-ui/icons/Pool";
import Brightness1 from "@material-ui/icons/Brightness1";
import LockOpen from "@material-ui/icons/LockOpen";
import DoneAll from "@material-ui/icons/DoneAll";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import DeleteForever from "@material-ui/icons/DeleteForever";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Shuffle from "@material-ui/icons/Shuffle";
import Cookies from "universal-cookie";
import Tooltip from "@material-ui/core/Tooltip";
import ReactGA from "react-ga";
import EcoIcon from "@material-ui/icons/Eco";

import { withStyles } from "@material-ui/core/styles";

ReactGA.initialize("UA-136662901-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const chiaAddress =
  "xch10tt2pcuz0vfgalq9gkm0ejev063t662qgdrvx3yg7wf8fxax48mqt7uasy";
//function numberWithCommas(x) {
//  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");
//}
function getReadableDifficulty(diff) {
  if (diff > 1000000000000)
    return Math.round((diff / 1000000000000) * 1000) / 1000 + " T";
  if (diff > 1000000000)
    return Math.round((diff / 1000000000) * 1000) / 1000 + " M";
  if (diff > 1000000) return Math.round((diff / 1000000) * 1000) / 1000 + " G";
  if (diff > 1000) return Math.round((diff / 1000) * 1000) / 1000 + " K";
  return diff;
}
function hhmmss(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  var days = Math.floor(hours / 24);
  hours = hours % 24;
  if (days === 0)
    if (hours === 0)
      if (minutes === 0) return "just started...";
      else return `${minutes}min ${secs}s`;
    else return `${hours}hr ${minutes}min ${secs}s`;
  else return `${days}d ${hours}hr ${minutes}min ${secs}s`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

function hideIpAddress(ipAddress) {
  return "###.###.###" + ipAddress.replace(/\b(?:\d{1,3}.){2}\d{1,3}\b/, "");
}

const styles = (theme) => ({
  root: {
    width: "100%",
    color: "white",
  },
  listPaper: {
    backgroundColor: "#000",
  },
  progress: {
    backgroundColor: "#4caf50",
    height: "3px",
    position: "absolute",
    right: 0,
    left: 0,
    bottom: 0,
    borderRadius: "0 4px 4px 4px",
  },
});

const cookie = new Cookies();

class AppHome extends Component {
  constructor(props) {
    super(props);
    this.setAuth = this.setAuth.bind(this);
    this.handleAppSubmit = this.handleAppSubmit.bind(this);
    this.disableReload = this.disableReload.bind(this);
    this.state = {
      response: [],
      chiaPool: [],
      chiaMiner: [],
      ebaydata: [],
      maxhash: [],
      variance: "",
      graph: [],
      chiagraph: [],
      auth: false,
      address: "",
      min: 0,
      average: 0,
      max: 0,
      counter: 0,
    };
  }
  reload() {
    if (this.state.address === "helium") {
      console.log("helium data reloaded");
      //Call Ebay API
      ebayApi
        .getResults(200, 700, "helium")
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log(response.status);
          }
        })
        .then((data) => {
          if (data.item) this.sortEbay(data.item);
          else {
            this.setState({ ebaydata: [] });
          }
        });
    } else {
      minerApi
        .getResults(this.state.address)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            cookie.remove("RigRadar", { path: "/" });
            this.setState({ auth: false });
            clearInterval(this.intervalId);
          }
        })
        .then((data) => this.setState({ response: data }));

      if (this.state.counter % 4 === 0) {
        if (this.state.address === "68.119.245.162:3337") {
          // Chia information
          minerApi
            .getChiaPoolInfo()
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              }
            })
            .then((data) => this.setState({ chiaPool: data }));

          // Get Chia mining information
          minerApi
            .getChiaMinerStats(chiaAddress)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              }
            })
            .then((data) => this.setState({ chiaMiner: data }));

          //Call Ebay API
          ebayApi
            .getResults(200, 600, "5700")
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                console.log(response.status);
              }
            })
            .then((data) => {
              if (data.item) this.sortEbay(data.item);
              else {
                this.setState({ ebaydata: [] });
              }
            });
        }
      }
      //console.log(this.state.counter );
      if (this.state.counter < 1)
        this.setState({ min: this.state.response.readableHashRate });
      this.setState({ counter: this.state.counter + 1 });
      this.setState({
        average:
          (this.state.average * (this.state.counter - 1) +
            this.state.response.readableHashRate) /
          this.state.counter,
      });
      if (this.state.response.readableHashRate > this.state.max)
        this.setState({ max: this.state.response.readableHashRate });
      if (this.state.response.readableHashRate < this.state.min)
        this.setState({ min: this.state.response.readableHashRate });
      this.loadGraph();
    }
  }

  handleAppSubmit(user) {
    this.setState({ address: user });
    this.intervalId = setInterval(this.reload.bind(this), 10000);
    this.reload();
  }

  loadGraph() {
    var graphdata;
    var chiagraphdata;
    if (this.state.graph.length > 100) {
      graphdata = this.state.graph.slice(
        this.state.graph.length - 100,
        this.state.graph.length
      );
    } else {
      graphdata = this.state.graph.slice();
    }

    if (this.state.chiagraph.length > 500) {
      chiagraphdata = this.state.chiagraph.slice(
        this.state.chiagraph.length - 500,
        this.state.chiagraph.length
      );
    } else {
      chiagraphdata = this.state.chiagraph.slice();
    }
    graphdata.push(this.state.response.readableHashRate);
    chiagraphdata.push(
      this.state.chiaMiner.result.currentEffectiveHashrate / 1000000000000
    );
    this.setState({ graph: graphdata });
    this.setState({ chiagraph: chiagraphdata });
  }

  componentDidMount() {
    this.setState({ counter: 0 });
    var user = cookie.get("RigRadar");
    console.log("Made it to the addres", this.state.address);
    if (user) {
      this.setState({ auth: true });
      this.setState({ address: user });
      this.intervalId = setInterval(this.reload.bind(this), 10000);

      if (this.state.address === "helium") {
        //Call Ebay API
        ebayApi
          .getResults(200, 700, "helium")
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              console.log(response.status);
            }
          })
          .then((data) => {
            if (data.item) this.sortEbay(data.item);
            else {
              this.setState({ ebaydata: [] });
            }
          });
      } else {
        // Call miner API
        minerApi
          .getResults(user)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              cookie.remove("RigRadar", { path: "/" });
              this.setState({ auth: false });
            }
          })
          .then((data) => this.setState({ response: data }));

        // Get Chia Block information
        minerApi
          .getChiaPoolInfo()
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((data) => this.setState({ chiaPool: data }));

        // Get Chia mining information
        minerApi
          .getChiaMinerStats(chiaAddress)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((data) => this.setState({ chiaMiner: data }));

        //Call Ebay API
        ebayApi
          .getResults(200, 600, "5700")
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              console.log(response.status);
            }
          })
          .then((data) => {
            if (data.item) this.sortEbay(data.item);
            else {
              this.setState({ ebaydata: [] });
            }
          });
      }
    } else {
      this.setState({ auth: false });
    }
  }

  sortEbay(items) {
    if (items.itemId !== undefined) this.setState({ ebaydata: [items] });
    else {
      this.setState({
        ebaydata: items.sort((a, b) =>
          new Date(a.listingInfo.startTime) < new Date(b.listingInfo.startTime)
            ? 1
            : -1
        ),
      });
    }
  }

  setAuth(data) {
    this.setState({ auth: data });
    if (data) this.setState({ address: data });
    else this.setState({ address: "Disconnected" });
  }
  disableReload() {
    clearInterval(this.intervalId);
  }
  getVariance() {
    // this.state.maxhash.stats && (Math.round(this.state.maxhash.stats.roundShares / this.state.maxhash.nodes[0].difficulty * 1000) / 10)
    return (
      Math.round(
        (this.state.maxhash.stats.roundShares /
          this.state.maxhash.nodes[0].difficulty) *
          1000
      ) / 10
    );
  }
  assignColor(gas) {
    switch (true) {
      case gas <= 100:
        return theme.palette.secondary.main;
      case gas > 100 && gas <= 200:
        return "white";
      case gas > 200 && gas <= 350:
        return theme.palette.warning.main;
      case gas > 350:
        return theme.palette.error.main;
      default:
        return "white";
    }
  }

  render() {
    const { classes } = this.props;
    const { auth } = this.state;

    return (
      <div className="App">
        <AppBar
          setAuth={this.setAuth}
          disableReload={this.disableReload}
          handleAppSubmit={this.handleAppSubmit}
        />
        <MuiThemeProvider theme={theme}>
          <div className="App-body">
            {!auth && this.state.address != "helium" && <Setup />}
            {auth && this.state.address != "helium" && (
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <div>
                    <div className="homeIcon">
                      <MergeType
                        style={{
                          fontSize: 64,
                          color: theme.palette.secondary.main,
                        }}
                      />
                    </div>
                    <div className="homeHeaderInfo">
                      {this.state.response.ip && (
                        <div>
                          <Brightness1
                            style={{
                              fontSize: 10,
                              color: this.state.auth
                                ? theme.palette.success.main
                                : "red",
                            }}
                          />{" "}
                          {hideIpAddress(this.state.response.ip)}
                        </div>
                      )}
                      <div>{this.state.response.version}</div>
                      {this.state.response.coin && (
                        <div style={{ color: "#FFF" }}>
                          ETH - ${this.state.response.coin.price}
                        </div>
                      )}
                    </div>
                    <h2>{this.state.response.rigName}</h2>
                    <div className="subHeaderText">
                      Currently reporting real-time stats from a{" "}
                      {this.state.response.software} mining rig.
                    </div>
                  </div>
                  <Divider />
                  {/* <Paper >This is a full grid? </Paper> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MainHashRate
                    hashrate={this.state.response.readableHashRate}
                    metric={this.state.response.pingMetric}
                    max={this.state.max}
                    min={this.state.min}
                    average={this.state.average}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.state.graph && <Graph data={this.state.graph} />}
                </Grid>
                {this.state.chiaMiner.result &&
                  this.state.address === "68.119.245.162:3337" && (
                    <Grid item xs={12} sm={6}>
                      <MainHashRate
                        hashrate={Number(
                          (
                            this.state.chiaMiner.result
                              .currentEffectiveHashrate / 1000000000000
                          ).toFixed(2)
                        )}
                        metric="TB"
                        average={Number(
                          (
                            this.state.chiaMiner.result
                              .averageEffectiveHashrate / 1000000000000
                          ).toFixed(2)
                        )}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} sm={6}>
                  {this.state.chiagraph && (
                    <Graph data={this.state.chiagraph} />
                  )}
                </Grid>
                <Grid item xs={12} sm={24}>
                  <Grid container spacing={24}>
                    <Grid item sm={3}>
                      <Paper>
                        <Pool className="iconFormat" style={{ fontSize: 30 }} />
                        {this.state.response.pool &&
                          this.state.response.pool.name}
                      </Paper>
                    </Grid>
                    {this.state.response.upTime && (
                      <Grid item sm={3}>
                        <Paper>
                          <AccessTime
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {hhmmss(this.state.response.upTime)}
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.pool && (
                      <Grid item sm={3}>
                        <Paper
                          style={{
                            backgroundColor:
                              this.state.response.pool &&
                              this.state.response.pool.variance < 1
                                ? theme.palette.warning.main
                                : theme.palette.primary.dark,
                          }}
                        >
                          <Shuffle
                            className="iconFormat"
                            style={{
                              fontSize: 30,
                              color:
                                this.state.response.pool &&
                                this.state.response.pool.variance > 100
                                  ? theme.palette.error.main
                                  : theme.palette.secondary.main,
                            }}
                          />
                          {this.state.response.pool &&
                            Math.round(this.state.response.pool.variance * 10) /
                              10}
                          % ({this.state.response.pool.hashrate} GHs)
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.calculatedRate && (
                      <Grid item sm={3}>
                        <Paper>
                          <MonetizationOn
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {this.state.response.calculatedRate &&
                            Math.round(
                              this.state.response.calculatedRate * 100
                            ) / 100}{" "}
                          coins/day
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.profit && (
                      <Grid item sm={3}>
                        <Tooltip
                          className={classes.tooltip}
                          title={
                            this.state.response.pool.unpaid
                              ? this.state.response.pool.unpaid +
                                " earned thus far ($" +
                                Math.round(
                                  this.state.response.coin.price *
                                    this.state.response.pool.unpaid *
                                    100,
                                  2
                                ) /
                                  100 +
                                ")"
                              : "n/a"
                          }
                          arrow
                          placement="bottom"
                        >
                          <Paper style={{ position: "relative" }}>
                            <AttachMoney
                              className="iconFormat"
                              style={{ fontSize: 30 }}
                            />
                            {this.state.response.profit &&
                              this.state.response.profit}{" "}
                            day
                            {this.state.response.pool.earningProgress && (
                              <div
                                className={classes.progress}
                                style={{
                                  background:
                                    parseFloat(
                                      this.state.response.pool.earningProgress
                                    ) > 100
                                      ? "#8bc34a"
                                      : "",
                                  width:
                                    parseFloat(
                                      this.state.response.pool.earningProgress
                                    ) > 100
                                      ? "100%"
                                      : this.state.response.pool
                                          .earningProgress,
                                }}
                              ></div>
                            )}
                          </Paper>
                        </Tooltip>
                      </Grid>
                    )}
                    {this.state.response.watts && (
                      <Grid item sm={3}>
                        <Paper>
                          <PowerIcon
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {this.state.response.watts} W
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.difficulty && (
                      <Grid item sm={3}>
                        <Paper>
                          <LockOpen
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {this.state.response.difficulty &&
                            getReadableDifficulty(
                              this.state.response.difficulty
                            )}
                          H/s
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.nethash && (
                      <Grid item sm={3}>
                        <Paper>
                          <NetworkCheck
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {this.state.response.nethash &&
                            Math.round(this.state.response.nethash * 100) /
                              100}{" "}
                          MH/s
                        </Paper>
                      </Grid>
                    )}

                    {this.state.response.coin && (
                      <Grid item sm={3}>
                        <Paper>
                          <LockOpen
                            className="iconFormat"
                            style={{ fontSize: 30 }}
                          />
                          {Math.round(
                            this.state.response.coin.difficulty * 10000
                          ) / 10000}{" "}
                          {this.state.response.coin.difficultyUnit}
                        </Paper>
                      </Grid>
                    )}
                    {this.state.response.gas && (
                      <Grid item sm={3}>
                        <Paper>
                          <LocalGasStationIcon
                            className="iconFormat"
                            style={{
                              fontSize: 30,
                              color: this.assignColor(this.state.response.gas),
                            }}
                          />
                          {this.state.response.gas} gwei
                        </Paper>
                      </Grid>
                    )}
                    <Grid item sm={3}>
                      <Paper>
                        <DoneAll
                          className="iconFormat"
                          style={{ fontSize: 30 }}
                        />
                        {this.state.response.shares &&
                          this.state.response.shares.accepted}
                      </Paper>
                    </Grid>
                    <Grid item sm={3}>
                      <Paper>
                        <DeleteForever
                          className="iconFormat"
                          style={{ fontSize: 30 }}
                        />
                        {this.state.response.shares &&
                          this.state.response.shares.rejected}
                      </Paper>
                    </Grid>

                    {this.state.chiaPool &&
                      this.state.address === "68.119.245.162:3337" && (
                        <Grid item sm={3}>
                          <Paper
                            style={{
                              backgroundColor:
                                this.state.chiaPool &&
                                this.state.chiaPool.result * 100 < 1
                                  ? theme.palette.warning.main
                                  : theme.palette.primary.dark,
                            }}
                          >
                            <EcoIcon
                              className="iconFormat"
                              style={{
                                fontSize: 30,
                                color:
                                  this.state.chiaPool &&
                                  this.state.chiaPool.result * 100 > 100
                                    ? theme.palette.error.main
                                    : theme.palette.secondary.main,
                              }}
                            />
                            {this.state.chiaPool.result &&
                              Math.round(this.state.chiaPool.result * 1000) /
                                10}
                            %
                          </Paper>
                        </Grid>
                      )}

                    {this.state.chiaMiner &&
                      this.state.address === "68.119.245.162:3337" && (
                        <Grid item sm={3}>
                          <Paper>
                            <DoneAll
                              className="iconFormat"
                              style={{
                                fontSize: 30,
                                color: theme.palette.secondary.main,
                              }}
                            />
                            {this.state.chiaMiner.result &&
                              this.state.chiaMiner.result.validShares}
                          </Paper>
                        </Grid>
                      )}
                    {this.state.chiaMiner &&
                      this.state.address === "68.119.245.162:3337" && (
                        <Grid item sm={3}>
                          <Paper>
                            <DeleteForever
                              className="iconFormat"
                              style={{
                                fontSize: 30,
                                color: theme.palette.secondary.main,
                              }}
                            />
                            {this.state.chiaMiner.result &&
                              this.state.chiaMiner.result.staleShares}
                          </Paper>
                        </Grid>
                      )}
                  </Grid>
                </Grid>

                {this.state.response.gpuDetails && (
                  <GpuList
                    data={this.state.response.gpuDetails}
                    fan={this.state.response.fanMetric}
                    hash={this.state.response.currentHashRate}
                  />
                )}
              </Grid>
            )}
            {this.state.ebaydata && this.state.ebaydata.length > 0 && (
              <EbayListings ebaydata={this.state.ebaydata} />
            )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

AppHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppHome);
