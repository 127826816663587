import 'whatwg-fetch'
const API_URL = "https://www.rigradar.com/api/services/ebay.php";

const ebayApi = {
    getResults: getResults
}
function getResults($min, $max, $search) {
    var request = new Request(API_URL+"?minPrice="+$min+"&maxPrice="+$max+"&searchTerm="+$search , {
        method: 'GET'
    })
    return fetch(request);
}
export default ebayApi;