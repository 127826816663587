import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import theme from '../theme'
import MultilineChart from '@material-ui/icons/MultilineChart';
import Grid from '@material-ui/core/Grid';




const styles = theme => ({
    signup : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

class Setup extends React.Component {

  render() {
    const { classes } = this.props;

    return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <div className={classes.signup}>
                    <div className="homeIcon"><MultilineChart style={{fontSize: 64, color: theme.palette.secondary.main}}/> </div>
                    Real-time updates from your rig    
                </div>
                <div className="subHeaderText" style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                    Private monitoring with no software installs. 
                </div>

                <div id='radar-container'>
                        <div class='radar'></div>
                    </div>
            </Grid>
            <Grid item sm={4}>
                <Paper>
                    <h2>Supported Miners</h2>
                    <p>RigRader currently works seemlessly with the following mining software. </p>
                    <ul>
                        <li>Claymore</li>
                        <li>lolMiner</li>
                        <li>SRBminer</li>
                        <li>XMR-Stak</li>
                        <li>Noncer Pro</li>
                    </ul>
                </Paper>
            </Grid>
            <Grid item sm={4}>
                <Paper>
                    <h2>Start</h2>
                    <p>All you have to do is input your rig's IP address with port in the top server bar. (i.e. 234.123.23.123:4444)</p>
                </Paper>
            </Grid>
            <Grid item sm={4}>
                <Paper>
                    <h2>About</h2>
                    <p>None of your rig information is stored on any server.  Your information is competely private.</p>
                </Paper>
            </Grid>
        </Grid>

    );
  }
}

Setup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Setup);