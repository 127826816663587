import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '../theme'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Paper from '@material-ui/core/Paper';
import { SwapSpinner  } from "react-spinners-kit";


const styles = theme => ({
    chartContainer : {
        backgroudColor: 'black'
    },
    loading : {
        minHeight: 128, 
        display: 'flex',
        justifyContent: 'center', alignItems: 'center'
    }
});

class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [3,2,5,6]
        }
      }


  render() {
    const { classes } = this.props;

    return (
     <MuiThemeProvider theme={theme}>
      <Paper>
          <h3>HISTORY</h3>
          {this.props.data.length < 1 && (
              <div className={classes.loading}>
                <SwapSpinner 
                color={theme.palette.secondary.main}/>
            </div>
          )}
          {this.props.data.length >= 1 && (
          <HighchartsReact
                highcharts={Highcharts}
                options={{
                    series: [{ 
                        name: "Hashrate", 
                        data: this.props.data ,

                    }],
                    colors: [theme.palette.secondary.main],
                    chart: {
                        backgroundColor: theme.palette.primary.dark,
                        height: 128,
                        defaultSeriesType: 'spline',

                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        borderWidth: 0,
                        backgroundColor:theme.palette.primary.main, 
                        style: {
                            color: '#FFF'
                        },
                        headerFormat: '',
                    },
                    xAxis: {
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        gridLineColor: 'transparent',
                        labels: {
                            enabled: false
                        },
                        tickPixelInterval: 10,
                        minorTickLength: 0,
                        tickLength: 0,
                        type: 'datetime',
                        title: {
                                enabled: false,
                                text: ''
                            }
                    },
                    yAxis: {
                        minRange: 2,
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        gridLineColor: 'transparent',
                        title: {
                            enabled: true,
                                text: ''
                            }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            pointInterval: 10000, 
                            marker: {
                                enabled: false
                            }
                        }
                    }
           
          
                  }}
                  
                containerProps={{className: 'chartContainer'}}
            />
            )}
      </Paper>
      </MuiThemeProvider>
    );
  }
}

Graph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Graph);