import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import theme from "../theme";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import InputBase from "@material-ui/core/InputBase";
import Cookies from "universal-cookie";
import minerApi from "../services/MinerService";
import Snackbar from "@material-ui/core/Snackbar";
import Close from "@material-ui/icons/Close";

const cookie = new Cookies();
const styles = {
  root: {
    flexGrow: 1,
    color: "#000",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  inputForm: {
    "& h2": {
      color: "#FFC107",
    },
  },
  multilineColor: {
    color: "white",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 5,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 150,
      "&:focus": {
        width: 200,
      },
    },
  },
};
class ButtonAppBar extends React.Component {
  //function ButtonAppBar(props) {

  state = {
    open: false,
    address: "",
    auth: false,
    snack: false,
    vertical: "top",
    horizontal: "right",
  };
  handleAddressChange = (event) => {
    var value = event.target.value.replace(/^https?\:\/\//i, "");
    value = value.replace("/", "");
    value = value.replace("summary", ""); // Make copying and pasting easier for lolminer.
    this.setState({ address: value });
  };
  handleSubmit = (event) => {
    cookie.set("RigRadar", this.state.address, { path: "/", maxAge: 2628000 });
    if (this.state.address !== "helium") {
      minerApi.getResults(this.state.address).then((response) => {
        if (response.status === 200) {
          this.setState({ auth: true });
          this.props.setAuth(cookie.get("RigRadar"));
          this.props.handleAppSubmit(this.state.address);
        } else {
          cookie.remove("RigRadar", { path: "/" });
          this.setState({ auth: false });
          this.setState({ snack: true });
        }
      });
    } else {
      this.setState({ auth: true });
      this.props.setAuth(cookie.get("RigRadar"));
    }
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleDelete = () => {
    this.props.setAuth(false);
    this.props.disableReload();
    cookie.remove("RigRadar", { path: "/" });
    this.setState({ auth: false });
  };
  handleClose = () => {
    this.setState({ snack: false });
  };
  componentDidMount() {
    //    cookie.remove('RigRadar', {path: '/'});
    var user = cookie.get("RigRadar");
    if (user) {
      this.setState({ auth: true });
    } else {
      this.setState({ auth: false });
    }
  }
  render() {
    const { classes } = this.props;
    const { address } = this.state;
    const { auth } = this.state;
    const { vertical, horizontal, snack } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App-nav">
          <AppBar
            position="static"
            style={{ backgroundColor: theme.palette.primary.dark, margin: -10 }}
          >
            <Toolbar>
              <span class="navbar-logo" href="#">
                RIGRADA<div class="flip">R</div>
              </span>

              <div className={classes.grow} />
              {auth && (
                <IconButton color="inherit">
                  <DeleteIcon
                    aria-haspopup="true"
                    onClick={this.handleDelete}
                  />
                </IconButton>
              )}
              {!auth && (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <InputBase
                      placeholder="Enter IP address..."
                      value={address}
                      onChange={this.handleAddressChange}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                    />
                  </form>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>

        <Dialog
          open={this.state.open}
          oncClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.inputForm}
          PaperProps={{
            style: {
              backgroundColor: "#1d1f24",
              color: "#FFF",
              padding: 0,
            },
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            className={this.props.classes.inputForm}
          >
            Get Set up
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.multilineColor}>
              To enable you're system, you need to ensure you are set to port
              forward, then put your IP address below (with port).
            </DialogContentText>
            <TextField
              autoFocus
              className="textfield"
              margin="dense"
              id="name"
              label="IP Address (XXX.XXX.XXX.XXX:YYYYY)"
              type="email"
              fullWidth
              InputProps={{
                classes: {
                  input: classes.multilineColor,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="default"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="outlined"
            >
              Monitor
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snack}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Error with IP address. Please check it (i.e. 123.456.789.43:3242)
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <Close className={classes.icon} />
            </IconButton>,
          ]}
        />
      </MuiThemeProvider>
    );
  }
}
ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
